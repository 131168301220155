
import { Component, Watch } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Getter, Mutation } from "vuex-class";
import { ITradeTermListRequest, ITradeTerm } from "@/types/trade_term";
import { IPagination } from "@/types";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // trade_term
  @Action("trade_term/adminGetList")
  public getList!: (request: ITradeTermListRequest) => boolean;

  @Getter("trade_term/list")
  public tradeTermList!: ITradeTerm[];

  @Getter("trade_term/pagination")
  public pagination!: IPagination<ITradeTerm[]>;

  @Mutation("trade_term/clear")
  public clear!: () => void;

  // watch
  @Watch("$route.query")
  public async changeQuery() {
    this.queryToParams();
    await this.getList(this.params);
  }

  // パラメータ定義
  public params: ITradeTermListRequest = {
    page: 1
  };

  //
  // variables
  //
  public headers = [{ text: "貿易条件", value: "terms", sortable: false }];

  //
  // methods
  //
  private async created() {
    this.clear();
    this.queryToParams();

    await this.getList(this.params);
  }

  // urlからパラメータをセットする
  private queryToParams() {
    const query = this.$route.query;
    this.params = {
      page: query.page ? Number(query.page) : 1
    };
  }

  // 1ページ目から検索
  // 主に検索条件が変更されたときに利用
  public async searchFirst() {
    this.params.page = 1;
    await this.paramsToQuery();
  }

  // URLのパラメータをセットする
  private paramsToQuery() {
    this.$router
      .push({
        path: this.$router.currentRoute.path,
        query: {
          page: this.params.page ? String(this.params.page) : "1"
        }
      })
      .catch(() => {});
  }

  // 行をクリックした際の挙動
  public rowClick(trade_term: ITradeTerm) {
    this.$router.push(`/admin/trade_term/${trade_term.id}/edit`);
  }
}
